












































import { Component, Vue } from "vue-property-decorator";
import { addAnalyticsEvent } from "@/plugins/firebase";
import { captureSentryException } from "@/plugins/sentry";

@Component
export default class Page404 extends Vue {
    mounted() {
        captureSentryException(new Error("not found:" + window.location.href));
        addAnalyticsEvent("404_not_found", { url: window.location.href });
        this.$root.$emit(
            this.$constants.NOTIFICATION_EVENTS.ERROR,
            `The requested URL ${window.location.href} was not found on this server.`
        );
    }

    homeClicked() {
        addAnalyticsEvent("home_button_clicked");
    }
}
